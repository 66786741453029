<!-- components/AppNumberField.vue -->
<template>
  <div>
    <label for="" class="fs-16 fw-700 d-block mb-2 label-container">
      <span class="label-right">
        <span>{{ label }}</span>
        <span v-if="must" class="required-start">*</span>
      </span>
      <span class="label-left">{{ englishLabel }}</span>
    </label>
    <div class="evaluation-input">
      <input
        :value="modelValue"
        @input="onInput"
        v-bind="$attrs"
        :maxlength="maxlength"
        class="number-input"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
defineOptions({
  inheritAttrs: false,
});

interface Props {
  modelValue: string;
  label?: string;
  englishLabel?: string;
  must?: boolean;
  maxlength?: number;
}
const { locale } = useI18n();
const props = defineProps<Props>();
const emit = defineEmits<{
  "update:modelValue": [payload: string];
}>();

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  // Convert Arabic digits to English digits
  value = value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) =>
    String.fromCharCode(d.charCodeAt(0) - 1632)
  );
  // Remove non-numeric characters
  value = value.replace(/\D/g, "");
  const maxLength = props.maxlength ?? 10;
  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }
  emit("update:modelValue", value);
  target.value = value;
};

// Ensure computed property reacts to locale changes
const isRTL = computed(() => {
  return locale.value === "ar";
});
</script>

<style scoped>
.label-container {
  display: flex !important;
  justify-content: space-between;
}

.label-right {
  text-align: right;
}

.label-left {
  text-align: left;
}

.evaluation-input input {
  width: 100%;
  height: 56px;
  background: #fff;
  border-radius: 10px;
  padding: 0 15px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  color: #000000 !important;
}

.required-start {
  color: red;
}

.evaluation-input input.number-input:focus {
  font-size: 16px;
  width: 100%;
}
</style>
